<template>
    <div style="background-color:white">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div >
      <!-- 切换按钮 -->
        <van-tabs
            v-model="lotteryKind"
            animated
            title-inactive-color="#000000"
            title-active-color="#f10400"
            line-width="50%"
            line-height="3px"
            @click="switchMethods"
        >
            <van-tab title="排列五" name="5"></van-tab>
            <van-tab title="七星彩" name="7"></van-tab>
        </van-tabs>
        <!-- <van-switch v-model="checked" @change="switchMethods">
            <template #node>
                <div>
                    <van-icon name="star" />
                    <div v-if="checked">
                        <van-icon name="star" />
                        <span >5</span>
                        <img src="../../assets/images/remove.png" />
                    </div>
                    <div v-else>
                        <van-icon name="like" />
                        <span >7</span>
                        <img  src="../../assets/images/right_arrow.png" />
                    </div>
                    

                </div>
            </template>
        </van-switch> -->
        
            <van-tabs  v-model="active" animated @click="onTabClick" title-active-color="red">
                <van-tab title="达人" disabled>达人</van-tab>
                <van-tab title="大师" >
                    <div class="master" >
                        <van-index-bar  :index-list="[]" highlight-color="red" >
                            <div class="masteritem" v-for="(item,index) in masters" :key="index">
                                <van-index-anchor index="index"><span class="red">|</span>{{item.name}}大师</van-index-anchor>
                                <hr style="width: 90%;opacity: 0.1;margin:0" />
                                <div v-if="(item.userinfo.length>0)" class="first" @click="gotopersonscheme(item.userinfo[0],item.name)">
                                    <img :src="item.userinfo[0].postAuthor.avatar_url"/>
                                    <p>近10中{{item.userinfo[0].hitPost}}</p>
                                </div>
                                <div v-if="(item.userinfo.length>1)" class="second" @click="gotopersonscheme(item.userinfo[1],item.name)">
                                    <img :src="item.userinfo[1].postAuthor.avatar_url"/>
                                    <p>近10中{{item.userinfo[1].hitPost}}</p>
                                </div>
                                <div v-if="(item.userinfo.length>2)" class="third" @click="gotopersonscheme(item.userinfo[2],item.name)">
                                    <img :src="item.userinfo[2].postAuthor.avatar_url"/>
                                    <p>近10中{{item.userinfo[2].hitPost}}</p>
                                </div>
                                <div v-if="(item.userinfo.length>3)" class="fourth" @click="goscheme(item)">
                                    <p style="border-radius:50%;border:1px solid gray;width:1rem;height:1rem;line-height:1rem;background-color:white;margin: 0.1rem;margin-bottom: 0.2rem;margin-left: 0.7rem;">大师</p>
                                    <p>更多</p>
                                </div>

                            </div>
                            
                        </van-index-bar>
                    </div>
                   
                </van-tab>
                <van-tab title="周榜">
                    <!-- 个人信息 姓名、排名、发帖数、是否达标 回报率 暂时未开发 -->
                    <div v-if="myself!==''" @click="showmyweekinfo=!showmyweekinfo" >
                        <div v-if="showmyweekinfo===false" class="tocenter"><img :src="avatarUrl"/><p>点击查看我在本周的成绩</p></div>
                        <div v-if="showmyweekinfo===true" >
                            <div class="myimg"><img  :src="myself.postAuthor.avatar_url"/> </div>
                            <div class="myinfo"><p>{{myself.postAuthor.author}}</p><p>排名:<span>{{myself.rankIndex}}</span></p><p>发帖数:<span v-if="(myself.postNum>=2 && myself.postNum!==null)">{{myself.postNum}}/2达标</span><span v-else>未达标</span></p></div>
                            <div class="myroi"><p>回报率:<span v-if="myself.weekRoi!==null">{{myself.weekRoi}}%</span></p><p class="red" v-if="(myself.postNum>=2 && myself.postNum!==null)">{{myself.postNum}}中{{myself.hitPost}}</p></div>
                        </div>
                    </div>
                    <div v-else><p style="text-align:center">本周暂无上周榜信息</p></div>
                    <p style="background-color:rgb(128 128 128 / 13%);">{{weekPeriod}}期 名人榜 周榜 {{weekstartDate | setDateyyyyMMDD}} - {{weekendDate | setDateyyyyMMDD}}  <span v-if="lotteryKind==='5'">排列五</span><span v-else >七星彩</span></p>
                    <div>
                        <div @click="gotoweekDetail(item.weekPeriod,item)" v-for="(item,index) in weekInfo" :key="index" style="border-bottom: solid 1px #8080802e; ">
                        <div :class="((index<3)?'red':'gray')" class="number">{{(index+1)}}</div>
                        <div class="center">
                            <img :src="item.postAuthor.avatar_url"/>
                            <div class="centerinfo">
                                <p style="font-size:0.5rem">{{item.postAuthor.author}}</p>
                                <p>发帖数量：{{item.postNum}}</p>
                            </div>
                        </div>
                        <div class="lastinfo">
                                <p v-show="(item.weekRoi>0)">{{item.weekRoi}}%</p>
                                <p class="red">{{item.postNum}}中{{item.hitPost}}</p>
                        </div>
                        </div>
                    </div>
                </van-tab>
                <van-tab title="月榜">
                    <!-- 个人信息 姓名、排名、发帖数、是否达标 回报率 暂时未开发 -->
                    <div v-if="myselfmonth!==''" @click="showmymonthinfo=!showmymonthinfo">
                        <div v-if="showmymonthinfo===false" class="tocenter"><img :src="avatarUrl"/><p>点击查看我在本月的成绩</p></div>
                        <div v-if="showmymonthinfo===true" >
                            <div class="myimg"><img  :src="myselfmonth.postAuthor.avatar_url"/> </div>
                            <div class="myinfo"><p>{{myselfmonth.postAuthor.author}}</p><p>排名:<span>{{myselfmonth.postAuthor.userSort}}</span></p><p>发帖数:<span v-if="(myselfmonth.postNum>=10 && myselfmonth.postNum!==null)">{{myselfmonth.postNum}}/10达标</span><span v-else>未达标</span></p></div>
                            <div class="myroi"><p>回报率:<span v-if="myselfmonth.monthRoi!==null">{{myselfmonth.monthRoi}}%</span></p><p v-if="(myselfmonth.postNum>=10 && myselfmonth.postNum!==null)">{{myselfmonth.postNum}}中{{myselfmonth.hitPost}}</p></div>
                        </div>
                    </div>
                    <div v-else><p style="text-align:center">本月暂无上月榜信息</p></div>
                    <p style="background-color:rgb(128 128 128 / 13%);">{{monthPeriod}}期 名人榜 月榜 {{monthstartDate | setDateyyyyMMDD}} - {{monthendDate | setDateyyyyMMDD}}  <span v-if="lotteryKind==='5'">排列五</span><span v-else >七星彩</span></p>
                    <div>
                        <div @click="gotomonthDetail(item.monthPeriod,item)" v-for="(item,index) in monthInfo" :key="index" style="border-bottom: solid 1px #8080802e; ">
                        <div :class="((index<3)?'red':'gray')" class="number">{{(index+1)}}</div>
                        <div class="center">
                            <img :src="item.postAuthor.avatar_url"/>
                            <div class="centerinfo">
                                <p style="font-size:0.4rem">{{item.postAuthor.author}}</p>
                                <p>发帖数量：{{item.postNum}}</p>
                            </div>
                        </div>
                        <div class="lastinfo">
                                <p v-show="(item.monthRoi>0)">{{item.monthRoi}}%</p>
                                <p class="red">{{item.postNum}}中{{item.hitPost}}</p>
                        </div>
                        </div>
                    </div>
                </van-tab>
                <van-tab title="往期" >
                    <van-collapse v-model="activeNames" accordion>
                        <van-collapse-item title="往期周榜" name="1" icon="label-o">
                            <van-cell-group inset>
                                
                                <van-field
                                    v-for="(item,index) in weekrankList" :key="index"
                                    clearable
                                    :label="item.weekPeriod "
                                    left-icon="label-o"
                                    :is-link="true"
                                    :readonly="true"
                                    label-width="1.5rem"
                                    :placeholder="item.startDate+'~'+item.endDate"
                                    @click="weekrankInfo(item.weekPeriod)"
                                />
                                
                            </van-cell-group>
                        </van-collapse-item>
                        <van-collapse-item title="往期月榜" name="2" icon="label-o">
                            <van-cell-group inset>
                                
                                <van-field
                                    v-for="(item,index) in monthrankList" :key="index"
                                    clearable
                                    :label="item.monthPeriod"
                                    left-icon="label-o"
                                    :is-link="true"
                                    :readonly="true"
                                    label-width="1.5rem"
                                    :placeholder="(item.startDate+'~'+item.endDate)"
                                    @click="monthrankInfo(item.monthPeriod)"
                                />
                                
                            </van-cell-group>
                        </van-collapse-item>
                    </van-collapse>
                </van-tab>
            </van-tabs>
            
        </div>
        <button class="buttonshow" @click="goPage('ranking/explain')">说明</button>
        
    </div>
   
</template>

<script>
import vHeader from "@/components/header.vue";
import Moment from 'moment';

export default {
    data(){
        return{
            
            title:this.$route.meta.title,
            // lotteryKind:this.$store.state.common.lotteryKind,
            userId:this.$store.state.common.loginInfo.userId,
            // active:2,
            showmyweekinfo:false,
            showmymonthinfo:false,
            weekInfo:[],
            weekPeriod:'',
            weekstartDate:'',
            weekendDate:'',
            monthInfo:[],
            monthPeriod:'',
            monthstartDate:'',
            monthendDate:'',
            activeNames:1,
            weekrankList:[],
            monthrankList:[],
            myself:'',
            myselfmonth:'',
            avatarUrl:this.$store.state.common.loginInfo.avatarUrl,
            indexList:[],
            master:[
                {name:'头尾大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',username:'半仙',roi:'384.85',continuous:'4',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',username:'麻神',roi:'344.85',continuous:'6',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',username:'诸葛',roi:'284.85',continuous:'3',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',username:'书呆子',roi:'184.85',continuous:'3',hitnum:'7'}]},
                {name:'中肚大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'芝麻大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'定头大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'定百大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'定十大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'定尾大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'杀头大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'杀百大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'杀十大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'杀尾大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'死数大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'稳码大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'头尾合大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'中肚合大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'ABCX大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'ABXD大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'AXCD大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'XBCD大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'ABXX大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'AXCX大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'XBXD大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'XXCD大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'二字现大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                {name:'三字现大师',userinfo:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',hitnum:'7'}]},
                
            ],
            masters:[],
        }
    },
    computed:{
        active: {
            get() {
                return this.$store.state.common.rangIndex_active;

            },
            set(vle) {
                this.$store.commit('common/updaterangIndex_active',vle);
            },
        },
        lotteryKind:{
            get(){
                return this.$store.state.common.lotteryKind
            },
            set(val){
                this.$store.commit("common/updateState", [
                    { name: ["lotteryKind"], value: val },
                ]);
            }
        },
        checked:{
            get(){
                return this.lotteryKind==='5'?true:false;
            },
            set(val){
                val===true?this.lotteryKind='5':this.lotteryKind='7'
            }
        }
    },
    filters:{
        setDateyyyyMMDD(vle){
      if (vle) { return Moment(vle).format('yyyy-MM-DD') }
      return '';
    }
    },
    methods:{
        async weekrankInfo(period){
            // this.$store.commit('common/updaterangPeriod','');
            sessionStorage.removeItem('period');
            let data={
            lotteryKind : this.lotteryKind,
            }
            if(period !== undefined){
                // console.log('往期进来的',period);
                // this.$store.commit('common/updaterangPeriod',period);
                sessionStorage.setItem('period',period);
                data.weekPeriod=period
                    this.active=2

            }
            this.weekInfo=[]
            const res = await this.$HTTP.post(this, 'API_WEEKLY_LIST', data, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
            if (res.statusCode === 200) {
                // console.log('week',res);
                if(res.data.weekRankList.length!==0){
                    res.data.weekRankList.forEach((ite)=>{
                        ite.postAuthor=JSON.parse(ite.postAuthor)
                        if(ite.weekRoi!==null){
                            ite.weekRoi=parseFloat(ite.weekRoi).toFixed(2)
                        }
                    })
                    this.weekPeriod=res.data.weekRankList[0].weekPeriod
                    this.weekstartDate=res.data.weekRankList[0].startDate
                    this.weekendDate=res.data.weekRankList[0].endDate
                    res.data.selfRanking.postAuthor=JSON.parse(res.data.selfRanking.postAuthor)
                }
                
                if(res.data.selfRanking.weekRoi!==null){
                    res.data.selfRanking.weekRoi=parseFloat(res.data.selfRanking.weekRoi).toFixed(2)
                }
                this.myself=res.data.selfRanking
                // console.log('周榜的个人信息myselfinfo',this.myself)
                // console.log('周榜信息',res)
                // console.log('test',res.periodNum)
                // console.log('test2',res.data.periodNum)
                this.weekInfo=res.data.weekRankList
                // this.getmyself(res.data.periodNum)
                // console.log('周榜信息',this.weekInfo)
            }
        },
        async monthrankInfo(period){
            // this.$store.commit('common/updaterangPeriod','');
            sessionStorage.removeItem('periodMonth');

            let data={
            lotteryKind : this.lotteryKind,
            }
            if(period !== undefined){
                // console.log('往期进来的',period);
                    data.monthPeriod=period
                    // this.$store.commit('common/updaterangPeriod',period);
                    sessionStorage.setItem('periodMonth',period);

                    this.active=3
            }
            this.monthInfo=[]
            const res = await this.$HTTP.post(this, 'API_MONTHLY_LIST', data, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
            if (res.statusCode === 200) {
                // console.log('month',res);
                res.data.monthlyRankList.forEach((ite)=>{
                        ite.postAuthor=JSON.parse(ite.postAuthor)
                        if(ite.monthRoi!==null){
                            ite.monthRoi=parseFloat(ite.monthRoi).toFixed(2)
                        }
                    })
                this.monthPeriod=res.data.monthlyRankList[0].monthPeriod
                this.monthstartDate=res.data.monthlyRankList[0].startDate
                this.monthendDate=res.data.monthlyRankList[0].endDate
                res.data.selfRanking.postAuthor=JSON.parse(res.data.selfRanking.postAuthor)
                if(res.data.selfRanking.monthRoi!==null){
                    res.data.selfRanking.monthRoi=parseFloat(res.data.selfRanking.monthRoi).toFixed(2)
                }
                this.myselfmonth=res.data.selfRanking
                // console.log('月榜的个人信息myselfinfo',this.myselfmonth)
                this.monthInfo=res.data.monthlyRankList
                // console.log('月榜信息',this.monthInfo)
            }
        },
        async getmonthrankList(){
            const res = await this.$HTTP.get(this, this.$API['API_MONTHLY_TITLE_LIST'], {lottery_kind: this.lotteryKind}, {headers: { access_token: this.$store.state.common.loginInfo.access_token }}, true)
            if (res.statusCode === 200) {
                // console.log('monthres',res)
                res.data.forEach((item)=>{
                    item.startDate= Moment(item.startDate).format('yyyy-MM-DD')
                    item.endDate= Moment(item.endDate).format('yyyy-MM-DD')
                })
                this.monthrankList=[]
                this.monthrankList=res.data
                // console.log('month',this.monthrankList)

            }
        },
        async getweekrankList(){
            const res = await this.$HTTP.get(this, this.$API['API_WEEKLY_TITLE_LIST'], {lottery_kind: this.lotteryKind}, {headers: { access_token: this.$store.state.common.loginInfo.access_token }}, true)
            if (res.statusCode === 200) {
                // console.log('weekres',res)
                res.data.forEach((item)=>{
                    item.startDate= Moment(item.startDate).format('yyyy-MM-DD')
                    item.endDate= Moment(item.endDate).format('yyyy-MM-DD')
                })
                this.weekrankList=[]
                this.weekrankList=res.data
                // console.log('week',this.weekrankList)

            }
        },
        onTabClick(){
        //   console.log('active:',this.active);  
            // console.log(this.myself,this.myselfmonth)
            this.myself=''
            this.myselfmonth=''
            // console.log(n)
            if(this.active===2){
                this.weekrankInfo()
            }
            if(this.active===3){
                this.monthrankInfo()
            }
            // if(n===4){
            //     this.getmonthrankList()
            //     this.getweekrankList()
            // }
        },
        gotoweekDetail(period,userinfo){
            // console.log(n)
            this.$router.push({
                                name:'userDetail',
                                query:{
                                    title:period+' 周榜',
                                    period:period,
                                    userinfo:JSON.stringify(userinfo),
                                    type:'1'
                                }
                            })
        },
        gotomonthDetail(period,userinfo){
            // console.log(period,startDate,endDate,userinfo)
            this.$router.push({
                                name:'userDetail',
                                query:{
                                    title:period+' 月榜',
                                    // userinfo:JSON.stringify({})
                                    period:period,
                                    userinfo:JSON.stringify(userinfo),
                                    type:'2'
                                    

                                }
                            })
        },
        //进入该方案的更多
        goscheme(n){
            // console.log(n)
            this.$router.push({
                                name:'ranking/scheme',
                                query:{
                                    title:n.name,
                                    userinfo:JSON.stringify(n.userinfo),

                                }

            })
        },
        //点击头像进入该用户的近十期该方案的列表中
        gotopersonscheme(n,name){
            // console.log(n)
            this.$router.push({
                                name:'ranking/personscheme',
                                query:{
                                    title:n.postAuthor.author,
                                    userinfo:JSON.stringify(n),
                                    schemename:name
                                }

            })
        },
        async getmasterinfo(){
            let data={
                  
                    lotteryKind:this.$store.state.common.lotteryKind,
                    
                }
            const res = await this.$HTTP.get(this, this.$API['API_MASTERINFO_ONE'], data, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
                    if (res.statusCode === 200) {
                       for(const key in res.data){
                        const item={
                            name:key,
                            userinfo:res.data[key]
                        }
                        this.masters.push(item)
                       }
                       this.masters.forEach((item)=>{
                            item.userinfo.forEach((ite)=>{
                              ite.postAuthor=JSON.parse(ite.postAuthor)  
                              ite.categoryRoi=parseFloat(ite.categoryRoi/100).toFixed(2)
                            })
                       })
                        // this.masters=Object.entries(res.data)
                        // console.log(this.masters)
                        // console.log(res)
                       

                    }
        },
        switchMethods(){
            switch(this.active){
                case 2:
                    if(sessionStorage.getItem('period')!==undefined&&sessionStorage.getItem('period')!==null){
                        this.weekrankInfo(sessionStorage.getItem('period'));

                    }else{
                        this.weekrankInfo();
                    }
                    this.getweekrankList();
                    this.getmonthrankList();
                    break;
                case 3:
                    if(sessionStorage.getItem('periodMonth')!==undefined&&sessionStorage.getItem('periodMonth')!==null){
                        this.monthrankInfo(sessionStorage.getItem('periodMonth'));
                    }else{
                        this.monthrankInfo();
                    }
                    this.getweekrankList();
                    this.getmonthrankList();
                    break;
                default:
                    this.getweekrankList();
                    this.getmonthrankList();
                    break;
                
            }
        }
        
      
    },
    components:{
        vHeader
    },
    mounted() {
    
    // console.log('active:',this.active);
    // console.log('rangIndex_active:',this.$store.state.common.rangIndex_active);

    // if(this.active===2&&sessionStorage.getItem('period')!==undefined&&sessionStorage.getItem('period')!==null){
    //     // console.log('week',sessionStorage.getItem('period'))
    //     this.weekrankInfo(sessionStorage.getItem('period'));

    // }else{
    //     this.weekrankInfo();
    // }
    // if(this.active===3&&sessionStorage.getItem('periodMonth')!==undefined&&sessionStorage.getItem('periodMonth')!==null){
    //     // console.log('month',sessionStorage.getItem('periodMonth'))
    //     this.monthrankInfo(sessionStorage.getItem('periodMonth'));
    // }else{
    //     this.monthrankInfo();
    // }
    this.switchMethods();
    
   

        // this.getweekinfo()
        // this.getmonthinfo()
     
        // if(this.$route.query.title==='test')
        // console.log(this.$route)
        // this.getmasterinfo()
        // console.log(this.weekInfo)
        // console.log(Object.keys(this.weekInfo).length===0)



        

    },
    created(){
        if (this.$store.state.common.loginInfo.access_token===undefined||this.$store.state.common.loginInfo.access_token===null||this.$store.state.common.loginInfo.access_token==='') {
            this.$router.replace('/login');
        }
    }
    
}
</script>

<style lang="less" scoped>

.buttonshow{
    position: fixed;
    bottom: 2.8rem;
    right: 0.1rem;
    border-radius: 50%;
    width: 1.3rem;
    height: 1.3rem;
    font-size: 0.2rem;
    background-color: red;
    color: white;
    border: none;
   
}
.fixedposition{
    position: fixed;top: 1.2rem;left: 0rem;bottom: 0rem;right: 0rem;overflow-y: scroll;-webkit-overflow-scrolling: touch;font-size: 0.4rem;
}
img{
    padding: 0;
    margin: 0.1rem;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
}
p{
    margin: 0;
    padding: 0;
    font-size: 0.3rem;
}
.tocenter{
    text-align: center;
}
.toinline{
    display: inline-block;
}
.red{
    color: red;
}
.gray{
    color:gray;
}
.myimg{
    display: inline-block;
    width: 20%;
    text-align: center;
}
.myinfo{
    display: inline-block;
    width: 56%;
}
.myroi{
    display: inline-block;
    width: 23%;
}
.number{
    position: relative;
    bottom: 0.2rem;
    text-align: center;
    display: inline-block;
    width: 10%;
}
.center{
    position: relative;
    bottom: 0.1rem;
    display: inline-block;
    width: 70%;
    img{
        position: relative;
        bottom: -0.3rem;
        margin:0.2rem;
        display: inline-block;
        

    }
    .centerinfo{
        
        display: inline-block;
    }
}
.lastinfo{
    position: relative;
    bottom: 0.3rem;
    display: inline-block;
    width: 20%;
}
.master{
    background-color: rgba(179, 175, 175, 0.17);
    .masteritem{
        margin: 0.08rem 0.1rem;
        background-color: white;
        p{
            text-align:center;
        }
        .first{
            width:25%;
            
            text-align: center;
            display: inline-block;
        }
        .second{
            width:25%;
            text-align: center;
            display: inline-block;
        }
        .third{
            width:25%;
            text-align: center;
            display: inline-block;
        }
        .fourth{
            width:25%;
            text-align: center;
            display: inline-block;
        }
    }
}

</style>